
.adopt-section1{
    width: 100%;
    height: 400px;
    background-image: url("../../images/AdoptCowImage1.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    backdrop-filter: blue(20%);
    justify-content: center;
    align-items: center;
}

.adopt-heading{
    height: 20%;
    width: 100%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 60px;
    color: rgb(9, 28, 9);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
}

.adopt-section2{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.adopt-desc{
    width: 80%;
    text-align: justify;
    height: auto;
    font-size: 24px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(123, 116, 131);
    font-weight: 600;
}

.adopt-section3{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.adopt-section3-content {
    width: 85%;
    height: auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; 
  }
  
  .adopt-for-cards {
    width: 500px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px; 
}

@media(max-width:500px){
    .adopt-section1{
        height: 300px;
    }
    .adopt-heading{
        font-size: 40px;
        font-weight: 600;
    }   
    .adopt-section3-content {
        width: 92%;
      }
      .adopt-desc{
        font-size: 16px;
    }
}
  
