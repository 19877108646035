
.about-us-container{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.about-us-image{
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../images/ContactImage1.jpg");
    background-size: cover;
    background-position: center;
}

.about-us-heading{
    font-size: 70px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 700;
    color: rgb(68, 67, 67);
}

.about-us-section2{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us-cont{
    width: 95%;
    height: auto;
    padding: 40px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.about-us-cont1-image{
    width: 50%;
    height: 90%;
}

.about-us-cont1-outer{
    width: 40%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-cont1-image img{
    height: 100%;
    width: 100%;
}

.about-us-cont1-inner{
    width: 90%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: large;
    color: gray;
    text-align: justify;
}

@media (max-width: 900px){
    .about-us-cont1-inner{
        font-size: 10px;        
        border: 2px solid rgb(84, 73, 73);
        border-radius: 20px;
        padding: 30px 30px;
    }
    .about-us-cont1-outer{
        padding: 30px 0px;
        width: 70%;
        height: 90%;
    }
    .about-us-cont{
        padding: 10px 0 10px 0;
    }
    .about-us-heading{
        font-size: 40px;
        font-weight: 400;
    }
}

@media (max-width:600px){
    .about-us-cont{
        flex-direction: column;
    }
    .about-us-cont1-image{
        width: 80%;
        height: 90%;
    }
    .about-us-cont1-outer{
        padding: 15px 0px;
        width: 70%;
        height: 100%;
    }
    .about-us-cont1-inner{
        font-size: 8px;
        padding: 12px 12px;
    }
    .about-us-heading{
        font-size: 30px;
        font-weight: 200;
    }
}