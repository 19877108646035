
.gallery-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-image1{
    background-image: url("../../images/GalleryCowImage1.jpg");
    background-position: 0cm;
    background-size: cover;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-image-title{
    font-size: 100px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(66, 30, 30);
}

.gallery-text-content{
    width: 90%;
    height: auto;
    padding: 30px 10px;
    display: flex;
    justify-items: center;
    align-items: center;
}
.gallery-description{
    color: rgb(95, 91, 91);
    font-size: 21px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    text-align: justify;
}
.gallery-images{
    width: 90%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 50px 20px;
    justify-content: center;
}

.gallery-image-border{
    height: auto;
    width: 36%;
    padding: 4px 4px;
    margin: 6px 6px;
}

.gallery-image-border img{
    height: 300px;
    width: 100%;
    border: 2px solid rgb(105, 94, 94);
    padding: 10px 10px;
    border-radius: 10px;
    background-color:rgba(255, 255, 255, 0.66);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.66);
}



@media(max-width:1200px){
    .gallery-image-border{
        width: 45%;
    }
}

@media (max-width:700px){
    .gallery-images{
        flex-direction: column;
        flex-wrap: none;
        align-items: center;
    }
    .gallery-image-border{
        width: 85%;
    }
    .gallery-image-border img{
        height: 275px;
    }
}
