
.nav-container{
    height: auto;
    width: 100%;
    background-color: rgb(100, 3, 3);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 2px 2px;
    justify-content: center;
    align-items: center;
}


.nav-cont1{
    height: auto;
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.nav-cont2{
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}

/* .forContacts{
} */

.forContacts img{
    margin: 1px 4px 0 0;
    height: 20px;
    width: 20px;
    filter: invert(100%);
    transition: filter 0.3s ease; 
}

.forContacts span{
    text-decoration: none;
    color: white;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

/* .forPhone2{
} */


.social-sites-circle{
    height: 35px;
    border: 3px solid rgb(233, 171, 171);
    border-radius: 50%;
    color: rgb(233, 171, 171);
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    cursor: pointer;
}

.social-sites-circle a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-sites-circle img{
    height: 20px;
    width: 20px;
    filter: invert(100%);
    transition: filter 0.3s ease;    
}

.social-sites-circle:hover{
    border: 3px solid white;
    color: white;
}


@media (min-width: 540px) and (max-width: 635px){
}

@media (max-width: 540px){
    .nav-cont1{
        flex-direction: column;
    }
}
