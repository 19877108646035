/* NX = not expanded */

.side-nav-container {
	background-color: rgb(93 9 9);
	width: 200px;
	height: 100vh;
	position: fixed;
	color: rgb(255,255,255);
	top: 0;
	right: -300px;
	transition: right 0.4s;
}

.side-nav-container.expanded{
	right: 0;
}

/* .side-nav-container-NX {
	width: 85px;
} */

.menu-item-para{
	margin: 2px 2px;
	padding: 5px 2px;
	text-align: center;
	font-size: 14px;
}

.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
	/* border: 2px solid white; */
	display: grid;
}

.nav-heading {
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 35px;
}

.nav-brand {
	display: flex;
	color: rgb(123,223,23);
	height: 35px;
}
.nav-brand img {
	width: 20px;
	padding: 0 5px;
	height: 35px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto;
}

.hamburger span {
	display: block;
	margin-top: 2px;
	background-color: rgb(226, 190, 183);
	border-radius: 15px;
	height: 5px;
	width: 20px;

	transition: 0.4s;
}

.hamburger:hover span {
	background-color: rgb(221, 156, 143);
}

.hamburger-in:hover span:nth-child(1) {
	width: 20px;
	height: 4px;
	transform: translateY(-0.3px) translateX(9px) rotate(30deg);
}
.hamburger-in:hover span:nth-child(2) {
	height: 5px;
	width: 30px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 20px;
	height: 4px;
	transform: translateY(0.3px) translateX(9px) rotate(-30deg);
}
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* When Not expanded -> out */
/* .hamburger-out {
	margin-left: 24px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(160deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(-160deg);
} */

.nav-menu {
	grid-template-rows: repeat(15, 1fr);
	margin-top: 0;
}

.menu-item {
	height: 35px;
	display: flex;
	color: rgb(255, 255, 255);
	text-decoration: none;
	text-transform: uppercase;
	margin: auto 20px;
	border-radius: 8px;
}

.menu-item-NX {
	margin: auto;
}
.menu-item:hover {
	background-color: rgb(235, 111, 111);
}
.menu-item img {
	width: 30px;
	padding: 0 20px;
}

.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
}

.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}

.nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: rgb(23,34,56);
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: rgb(123,35,132);
}