
.payment-success-container{
    border-top: 2px solid rgb(183, 168, 168);
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 100px;
}


.payment-success-inner{
    height: 200px;
    width: 60%;
    padding: 30px 30px;
    color: rgb(63, 51, 51);   
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.payment-success-heading{
    color: green;
    font-weight: 900;
    font-size: 25px;
}


@media (max-width:850px){
    .payment-success-inner{
        width: 98%;
        padding: 10px 10px;
        font-size: 15px;
        font-weight: 200;
    }   
    .payment-success-container{
        height: auto;
        width: 100%;
        padding: 20px 20px;
    }
    .payment-success-heading{
        font-weight: 500;
        font-size: 18px;
    }
}
