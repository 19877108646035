.donate-container{
    height: auto;
    width: 100%;
}

.donate-section1{
    height: 300px;
    background-image: url("../../images/SponsorCowImage1.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    backdrop-filter: blue(20%);
    justify-content: center;
    align-items: center;
}

.donate-heading{
    height: 20%;
    width: 100%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 60px;
    color: rgb(9, 28, 9);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
}

/* .donate-section2{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.donate-cont1{
    height: 300px;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.donate-form{
    display: flex;
    flex-direction: column;
    background-color:rgba(255, 255, 255, 0.66);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.66);
    width: 100%;
    padding: 20px 20px;
    justify-content: space-evenly;
    border-radius: 15px;
    align-items: center;
}

.donate-form-heading{
    padding: 5px 10px;
    color: rgb(79, 71, 71);
    font-size: large;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.donate-choice-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 6px 10px;
}

.donate-amount-btn{
    cursor: pointer;
    width: 90px;
    border: 1px solid black;
    padding: 6px 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background-color: brown;
    border: 2px solid brown;
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.donate-amount-btn:hover{
    color: brown;
    background-color: white;
}

.donate-form input{
    width: 80%;
    padding: 6px 10px;
    border: 1px solid rgb(114, 100, 100);
    border-radius: 4px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


.donate-submit-btn{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 4px 10px;
}

.donate-submit-btn button{
    cursor: pointer;
    padding: 10px 20px;
    color: white;
    background-color: rgb(15, 15, 62);
    border: 2px solid (rgb(15,15,62));

    font-weight: 700;
    border-radius: 6px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.donate-submit-btn button:hover{
    background-color: white;
    color: rgb(15,15,62);
} */

.donate-section2{
    height: 450px;
    width: 100%;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 2px;
}

.donate-cont1{
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.donate-section2-heading{
    flex: 1;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(83, 73, 73);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}
.donate-section2-content{
    flex: 5;
    display: flex;
    flex-direction: row;
}

.qrcode-upperbox{
    border-right: 3px dotted black;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrcode-lowerbox{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px 10px 10px;
}

.qrcode-lowerbox img{
    height: 370px;
    width: 370px;
}

.upi-upperbox{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upi-lowerbox{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(83, 73, 73);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    padding: 20px 8px;
}

.donate-section3{
    width: 100%;
    height: auto;
    padding: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.donate-section3-inner{
    width: 80%;
    height: auto;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 3px dotted rgb(59, 53, 53);
}

.donate-section3-heading{
    flex: 1;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(83, 73, 73);
    display: flex;
    align-items: center;
}

.donate-section3-details{
    flex: 4;
    width: 60%;
    display: flex;
    flex-direction: column;
}

.donate-section3-details{
    display: flex;
    flex: row;
    justify-content: space-between;
}

.details-upperbox{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.details-heading{
    width: 40%;
    color: rgb(93, 75, 75);
    padding: 10px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

@media(max-width:1100px){
    .donate-cont1{
        width: 90%;
    }
}
@media(max-width:900px){
    .qrcode-lowerbox img{
        height: 300px;
        width: 300px;
    }
}

@media (max-width:800px){
    /* .donate-cont1{
        width: 80%;
    } */
    .donate-cont1{
        width: 98%;
    }
    .qrcode-lowerbox img{
        height: 250px;
        width: 250px;
    }
}

@media (max-width:630px){
    /* .donate-cont1{
        width: 90%;
    } */
    .donate-section3-inner{
        width: 90%;
    }
    .donate-section2-content{
        flex-direction: column;
    }
    .qrcode-upperbox{
        border-right: none;
    }
    .qrcode-lowerbox{
        width: 85%;
        border-bottom: 3px dotted black;
    }
    /* .donate-form-heading{
        font-size: 15px;
    }
     */
    /* .donate-amount-btn{
        padding: 3px 8px;
        font-weight: 400;
    } */
    
    /* .donate-submit-btn{
        padding: 8px 14px;
    } */
    
    /* .donate-submit-btn button{
        padding: 8px 15px;
        font-weight: 400;
    } */
    .donate-section3-inner{
        width: 90%;
    }
    
    .donate-section3-heading{
        font-size: 15px;
    }
    
    .donate-section3-details{
        width: 80%;
        font-size: 12px;
    }
    .donate-section3-details{
        width: 90%;
    }
}
@media (max-width:455px){
    /* .donate-cont1{
        width: 90%;
    } */
    .donate-section3-inner{
        width: 90%;
    }
    /* .donate-form-heading{
        font-size: 13px;
    } */
    
    /* .donate-amount-btn{
        padding: 2px 6px;
        font-weight: 200;
    } */
    
    
    /* .donate-submit-btn button{
        padding: 8px 15px;
        font-weight: 400;
    } */
    .donate-section3-inner{
        width: 90%;
    }
    
    .donate-section3-heading{
        font-size: 12px;
    }
    
    .donate-section3-details{
        width: 90%;
        font-size: 10px;
    }
}

@media (max-width:430px){
    /* .donate-choice-btns{
        flex-direction: column;
    } */
    /* .donate-amount-btn{
        margin: 5px 0;
    } */
    .upi-lowerbox{
        font-size: medium;
    }
}

@media(max-width:300px){
    .upi-lowerbox{
        font-size: small;
    }
}