.contact-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.contact-image-container{
    background-image: url("../../images/ContactImage1.jpg");
    background-position: center;
    background-size: cover;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-image-title{
    font-weight: 700;
    font-size: 70px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(68, 67, 67);
}


.contact-section2{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 40px;
    justify-content: space-evenly;
}

.contact-subcontainer{
    height: 180px;
    width: 30%;
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
}

.contact-inner-cont1{
    flex: 2;
    width: 100%;
    font-size: 40px;
    display: flex;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    justify-content: center;
    align-items: center;
    color: rgb(73, 66, 66);
}
.contact-inner-cont2{
    flex: 4;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
}
.contact-address{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(105, 103, 103);
    font-size: 20px;
}

.contact-section3{
    height: auto;
    padding: 40px 10px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.map-heading{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(89, 84, 84);
}

.contact-map{
    height: 400px;
    width: 60%;
}


@media(max-width:1000px){
    .contact-map{
        height: 400px;
        width: 80%;
    }
    .contact-inner-cont1{
        font-size: 30px;
    }
    .contact-address{
        font-size: 15px;
    }
}
@media(max-width:750px){
    .contact-map{
        width: 95%;
    }
    .contact-inner-cont1{
        font-size: 22px;
    }
    .contact-address{
        font-size: 13px;
    }
    .map-heading{
        height: 30px;
        font-size: 20px;
    }
    .contact-subcontainer{
        width: 40%;
    }
    .contact-image-title{
        font-weight: 400;
        font-size: 45px;;
    }
}

@media (max-width:500px){
    .contact-section2{
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: none;
    }
    .contact-subcontainer{
        width: 70%;
    }
}
