.navbar-container{
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
}

.navbar-cont1{
    /* border: 2px solid green; */
    flex: 2;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-cont2{
    flex: 6;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-right: 3px solid rgb(100, 3, 3);
}

.navbar-cont3{
    flex: 2;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .cow-logo-container{
} */
.navbar-cont4{
    flex: 0.5;
}
.cow-logo-container img{
    height: 70px;
    width: 110px;
}

.nav-link{
    text-decoration: none;
    font-size: larger;
    color: grey;
}

.forNavLink-2{
    font-size: larger;
    color: grey;
    cursor: pointer;
}

.forNavLink-2 img{
    height: 15px;
    width: 15px;
}

.donate-button{
    height: 50px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(100, 3, 3);
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    color: rgb(100, 3, 3);
    cursor: pointer;
    transition: 0.3s ease;
}

.donate-button:hover{
    background-color: rgb(100, 3, 3);
    color: white;
}

.side-navbar-btn{
    margin: 0 20px 0 0;
    cursor: pointer;
    background-color: rgb(100, 3, 3);
    border: none;
    border-radius: 5px;
    padding: 3px 5px 5px 5px;
  }

  .side-navbar-btn:hover{
    background-color: rgb(100, 3, 3);
  } 
  
  .side-navbar-btn span{
    display: block;
    margin-top: 2px;
    background-color:white;
    border-radius: 15px;
    height: 5px;
    width: 20px;
    
  }

@media (max-width:800px){
    .navbar-cont2{
        display: none;
    }
    .navbar-cont4{
        display: block;
    }
    .navbar-container{
        height: 80px;
    }
    .cow-logo-container img{
        height: 50px;
        width: 80px;
    }
    .navbar-cont1{
        height: 60px;
    }
    .donate-button{
        height: 30px;
        width: 90px;
        font-weight: 600;
        font-size: 15px;
    }
}

@media (min-width:801px){
    .navbar-cont2{
        display: flex;
    }
    .navbar-cont4{
        display: none;
    }
}