.footer-container{
    height: auto;
    width: 100%;
    background-color: #141010;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.footer1{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    border-bottom: 2px solid grey;
}

.footer2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:medium;
    font-weight: 600;
    color: white;
    padding: 20px 20px;
}

.footer-cont1{
    flex: 1.5;
    height: 300px;
    display: flex;
    flex-direction: column;
}

.footer-logo{
    flex: 4;
    padding: 0 0 0 80px;
}
.footer-logo img{
    height: 130px;
    width: 180px;
}
.footer-des{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 300;
    flex: 3;
    text-align: justify;
    color: white;
}

.footer-social{
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.social-sites-circle{
    height: 35px;
    border: 3px solid white;
    border-radius: 50%;
    color: rgb(85, 8, 8);
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    cursor: pointer;
    margin: 0 10px;
}

.social-sites-circle a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-sites-circle img{
    height: 20px;
    width: 20px;
    filter: invert(100%);
    transition: filter 0.3s ease;    
}

.social-sites-circle:hover{
    border: 3px solid white;
    background-color: white;
    color: white;
}

.social-sites-circle:hover img{
    filter: brightness(10%);
}

.footer-cont2{
    flex: 1;
    height: 300px;
    display: flex;
    flex-direction: column;
}
.quick-links{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 0 0 20px;
    font-size: x-large;
    font-weight: 600;
    color: white;
}
.actual-links{
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: top;
    padding: 0 0 0 30px;
}

.actual-links span{
    padding: 4px 4px;
    margin: 4px 0;
}

.actual-links a{
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
    color: white;
}

.actual-links a:hover{
    color: rgb(227, 142, 142);
}

.footer-cont3{
    flex: 1;
    height: 300px;
    display: flex;
    flex-direction: column;
}

.address{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 0 0 20px;
    font-size: x-large;
    font-weight: bold;
    color: white;   
}

.actual-contact{
    flex: 6;
    display: flex;
    flex-direction: column;
}

.actual-address{
    flex: 4;
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: row;
}

.forLogo{
    flex: 1;
}

.forAddress{
    flex: 9;
    display: flex;
    flex-direction: column;
}
.forAddress span{
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin: 0 0 8px 0;
}
.actual-address img{
    height: 24px;
    width: 24px;
    filter: invert(100%);
    transition: filter 0.3s ease; 
    margin: 0 10px 0 0;
}

.forPhone{
    flex: 1;
    display: flex;
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    /* border: 2px solid white; */
    padding: 0 0 0 20px;
    cursor: pointer;
}

.forPhone img{
    height: 24px;
    width: 24px;
    filter: invert(100%);
    transition: filter 0.3s ease; 
    margin: 0 10px 0 0;
}

.forGmail{
    flex: 1;
    display: flex;
    color: white;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    /* border: 2px solid white; */
    padding: 2px 20px;
    cursor: pointer;
}

.forGmail img{
    height: 24px;
    width: 24px;
    filter: invert(100%);
    transition: filter 0.3s ease;
    margin: 0 10px 0 0; 
}

@media (max-width: 1000px){
    .footer-logo img{
        height: 90px;
        width: 140px;
    }
}

@media (max-width:800px){
    .footer1{
        flex-direction: column;
    }
    .footer-logo img{
        height: 130px;
        width: 240px;
    }
    .footer-des{
        padding: 20px 0;
    }
    .footer-logo{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 0;
    }
    .footer-cont1{
        padding: 20px 0;
    }
    .footer-cont2{
        padding: 20px 0;
    }
    .footer-cont3{
        padding: 20px 0;
    }
}

@media (max-width: 400px){
    .footer2{
        font-size: 12px;
        font-weight: 400;
    }
}