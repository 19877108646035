

.imageContainer img{
    height: 600px;
    width: 100%;
}

.home-section-2{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 10px;
}

.section2-desc{
    padding: 10px 0 20px 0;
    flex: 3;
    display: flex;
    align-items:center;
    justify-content: center;
}

.actual-desc{
    font-size: x-large;
    font-weight: bold;
    height: auto;
    width: 80%;
    text-align:justify;
    color: rgb(48, 47, 47);
}

.section2-smallDesc{
    padding: 10px 0 60px 0;
    flex: 1;
    display: flex; 
    justify-content: center;
}

.smallTalk-Desc{
    font-size: large;
    font-weight: 600;
    color: grey;
    width: 60%;
    display: flex;
    justify-content: center;
}

.home-section-3{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.home-section-outercard{
    width: 30%;
    height: 300px;
    border: 3px solid rgb(236, 190, 190);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.home-section-innercard{
    width: 80%;
    height: 250px;   
    display: flex;
    flex-direction: column;
}

.card-icon{
    width: 100%;
    flex: 2;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -70px;
}
.card-heading{
    width: 100%;
    flex: 2;
    color: #111111;
    font-size: x-large;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family:'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
}
.card-heading:hover{
    color: rgb(167, 36, 36);
}
.card-subheading{
    width: 100%;
    flex: 1;
    color: #767676;;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    font-weight: 500;
}
.card-desc{
    text-align: justify;
    width: 100%;
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.40);
}
.card-button{
    width: 100%;
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-button button{
    height: 50px;
    width: 160px;
    font-size: large;
    text-transform: uppercase;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
    background-color: rgb(173, 44, 44);
    border: 2px solid rgb(173, 44, 44);
    color:white;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.3s ease;
}

.card-button button:hover{
    background-color: white;
    border-color: 2px solid rgb(173, 44, 44);
    color: rgb(173, 44, 44);
}

.inner-card-icon{
    border: 2px solid rgb(236, 190, 190);
    border-radius: 150px;
    padding: 15px 15px;
    z-index: 10;
    background-color: white;
}

.inner-card-icon img{
    height: 50px;
    width: 60px;
    filter: contrast(20%);
}

.home-section-4{
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 50px 0;
}

.section4-head1{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: larger;
    color: #767676;
}
.section4-head2{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    color: #414040;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.home-section-5{
    height: 300px;
    width: 100%;
    background-image: url("../../images/HomeCowImage2.jpg");
    background-size: cover;
    background-position: center;   
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section5-container{
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.section5-inner-box{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-upper-box{
    flex: 3;
    width: 100%;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: end;
    color: rgb(224, 226, 228);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.inner-lower-box{
    flex: 1;
    width: 100%;
    font-size: 20px;
    display: flex;
    color: rgb(200, 221, 241);
    justify-content: center;
    align-items: baseline;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


@media (max-width:1000px){
    .imageContainer img{
        height: 350px;
    }
}

@media (max-width:600px){
    .imageContainer img{
        height: 250px;
    }
    .actual-desc{
        font-size: 18px;
        font-weight: 600;
    }
    .smallTalk-Desc{
        font-size: 14px;
        font-weight: 300;
        color: grey;
        width: 60%;
        display: flex;
        justify-content: center;
    }
}

@media (max-width:1100px){
    .card-button button{
        height: 30px;
        width: 120px;
        font-size: 12px;
        font-weight: 400;
    }
    .inner-upper-box{
        font-size: 45px;
    }
    .inner-lower-box{
        font-size: 16px;
    }
}


@media (max-width:800px){
    .home-section-3{
        flex-wrap: none;
        flex-direction: column;
    }
    .home-section-outercard{
        width: 70%;
        padding: 130px 0 130px 0;
        border: 2px solid black;
        margin: 0 0 70px 0;
    }
    .inner-upper-box{
        font-size: 35px;
    }
    .inner-lower-box{
        font-size: 14px;
    }

}

@media (max-width:450px){
    .home-section-4{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 0 50px 0;
    }
    
    .section4-head1{
        font-size: 16px;
    }
    .section4-head2{
        font-size: 40px;
    }
    .inner-upper-box{
        font-size: 24px;
    }
    .inner-lower-box{
        font-size: 8px;
    }
}